<template>
  <div class="radius-ips">
    <h1 class="titulo-card fs-4">Servidores / IP Radius</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>IP</th>
              <th>Identificação</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="ip in radiusIPs" :key="ip.id" @click="handleEditRadiusIP(ip)" class="cursor-pointer">
                <td scope="row">{{ip.ip}}</td>
                <td>{{ip.description}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'

export default {
  name: 'IspRadiusIP',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      radiusIPs: [],
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/isp/radius-ips?${this.paginador.requestParams}&orderBy[ip]`).then(res => {
        loading(false);
        this.radiusIPs = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.$router.push(`/configuracoes/servidores/radius-ips/novo`);
    },

    handleEditRadiusIP(ip) {
      this.$router.push(`/configuracoes/servidores/radius-ips/${ip.id}/edit`);
    },
  },
  created() {
    this.handlePesquisa();
  }
}
</script>
